import {Form, Select, Switch} from 'antd';
import styles from './createWalletCurrency.module.scss';
import {useState} from 'react';
import DepositWalletFields from '../view/WalletFields/DepositWalletField';
import WithdrawalWalletFields from '../view/WalletFields/WithdrawalWalletFields';
import backIcon from 'images/back.png';
import {useNavigate} from 'react-router-dom';
import {walletCurrencies} from 'utils/mock';
import {handleUpdatePaymentProviderSetting} from '../actions/MutationRequests';
import {toast} from 'react-toastify';
import {useLazyQuery, useMutation} from '@apollo/client';
import {UPDATE_PAYMENT_PROVIDER_SETTINGS} from 'graphql/mutations/wallet';
import {GET_BOTH_PLAFORM_AND_WALLET_CURRENCY} from 'graphql/queries/wallet';

export const checkFeeValues = (type, amount) => {
  if (!type?.length || !amount) return false;
  const isValid = type.every(
    item =>
      (item.fixedFee < amount && item.fixedFee) ||
      (item.variableFee < amount && item.variableFee),
  );
  return !isValid;
};

const CreateProviderSettings = () => {
  const [provider, setProvider] = useState('');
  const [enableProvider, setEnableProvider] = useState(true);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [updatePaymentProviderCurrencySettings, {loading}] = useMutation(
    UPDATE_PAYMENT_PROVIDER_SETTINGS,
  );

  const [getPaymentProviderByProviderName, {data}] = useLazyQuery(
    GET_BOTH_PLAFORM_AND_WALLET_CURRENCY,
  );

  const handleCreate = values => {
    if (checkFeeValues(form, values.minWithdrawalAmount))
      return toast.error(
        'Fee Values must be LESS than Minimum Withdrawal Amount',
      );

    if (checkFeeValues(values?.depositFees, values.minDepositAmount))
      return toast.error('Fee Values must be LESS than Minimum Deposit Amount');

    handleUpdatePaymentProviderSetting(
      updatePaymentProviderCurrencySettings,
      values,
      provider,
      enableProvider,
      selectedCurrency,
      values?.depositFees,
      values?.withdrawalFees,
      values?.depositDiscount,
      values?.withdrawalDiscount,
      navigate,
    );
  };

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} />
        <h1>Create Provider Settings</h1>
      </div>
      <Form layout="vertical">
        <Form.Item
          name="provider"
          label="Payment Provider"
          rules={[{required: true, message: 'Please Select Payment Provider'}]}>
          <Select
            placeholder="Please Select a Payment Provider"
            style={{width: 700}}
            value={provider}
            onChange={e => setProvider(e)}>
            <Select.Option key="FLUTTERWAVE">Flutterwave</Select.Option>
            <Select.Option key="DWOLLA">Dwolla</Select.Option>
            <Select.Option key="PAYSTACK">Paystack</Select.Option>
            <Select.Option key="PAYPAL">PayPal</Select.Option>
            <Select.Option key="SVB">SVB</Select.Option>
            <Select.Option key="REVOLUT">Revolut</Select.Option>
            <Select.Option key="WISE">Wise</Select.Option>
            <Select.Option key="PLATFORM">Platform</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Enable Provider" name="allowedDeposit">
          <Switch
            onChange={e => setEnableProvider(e)}
            defaultChecked={enableProvider}
          />
        </Form.Item>
        <Form.Item name="currency" label="Select Currency">
          <Select
            value={selectedCurrency}
            style={{width: 700}}
            placeholder="Please Select a currency"
            onChange={e => {
              setSelectedCurrency(e);
              getPaymentProviderByProviderName({
                variables: {
                  providerName: provider,
                  currency2: e,
                },
              });
            }}>
            {walletCurrencies.map(data => (
              <Select.Option key={data.key}>{data.key}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <Form onFinish={handleCreate} layout="vertical" form={form}>
        {selectedCurrency && (
          <>
            <DepositWalletFields
              selectedCurrency={selectedCurrency}
              form={form}
              currencyLimits={
                data?.getSupportedCurrencyByCurrencyName?.deposit?.limit
              }
              currencyDetails={{}}
            />
            <WithdrawalWalletFields
              selectedCurrency={selectedCurrency}
              loading={loading}
              currencyLimits={
                data?.getSupportedCurrencyByCurrencyName?.withdrawal?.limit
              }
              currencyDetails={{}}
              form={form}
            />
          </>
        )}
      </Form>
    </div>
  );
};

export default CreateProviderSettings;
