import {toast} from 'react-toastify';
import {enabledPaymentMethod} from 'utils/mock';

export const handleUpdatePaymentProviderSetting = (
  mutation,
  values,
  provider,
  enableProvider,
  selectedCurrency,
  depositFeeValues,
  withdrawalFeeValues,
  depositDiscount,
  withdrawalDiscount,
  navigate,
) => {
  // Get all unique fee types from enabledPaymentMethod
  const feeTypes = [...new Set(enabledPaymentMethod.map(data => data.key))];

  const feeSchedule = generateFeeSchedule(
    feeTypes,
    depositFeeValues,
    withdrawalFeeValues,
  );

  // const discountFeeSchedule = generateFeeSchedule(
  //   feeTypes,
  //   depositDiscount,
  //   withdrawalDiscount,
  // );

  mutation({
    variables: {
      input: [
        {
          provider: provider,
          currency: selectedCurrency,
          enabled: enableProvider,
          feeSchedule: feeSchedule,
          // discount: discountFeeSchedule,
          deposit: {
            enabled: values.allowedDeposit,
            maxDepositAmount: Number(values.maxDepositAmount),
            minDepositAmount: Number(values.minDepositAmount),
            maxNumberOfDepositPerDay: Number(values.depositADay),
            limit: {
              daily: Number(values.dailyLimit),
              weekly: Number(values.weeklyLimit),
              monthly: Number(values.monthlyLimit),
            },
          },
          withdrawal: {
            enabled: values.allowedWithdrawal,
            maxWithdrawalAmount: Number(values.maxWithdrawalAmount),
            minWithdrawalAmount: Number(values.minWithdrawalAmount),
            maxNumberOfWithdrawalPerDay: Number(values.withdrawalDepositADay),
            limit: {
              daily: Number(values.dailyWithdrawalLimit),
              weekly: Number(values.weeklyWithdrawalLimit),
              monthly: Number(values.monthlyWithdrawalLimit),
            },
          },
        },
      ],
    },
  })
    .then(({data: {updatePaymentProviderCurrencySettings}}) => {
      if (updatePaymentProviderCurrencySettings.__typename === 'Error') {
        toast.error(updatePaymentProviderCurrencySettings.message);
      } else {
        toast.success(`${selectedCurrency} Updated Successfully.`);
        navigate(-1);
      }
    })
    .catch(error => {
      toast.error(error);
    });
};

const generateFeeSchedule = (
  feeTypes,
  depositFeeValues,
  withdrawalFeeValues,
) => {
  return feeTypes
    .map(feeType => {
      // Filter all deposit and withdrawal fees for the current feeType

      const depositFeesForFeeType = depositFeeValues?.filter(
        data => data?.feeType === feeType,
      );
      const withdrawalFeesForFeeType = withdrawalFeeValues.filter(
        data => data?.feeType === feeType,
      );

      // Combine both deposit and withdrawal fees
      const combinedFees = [
        ...depositFeesForFeeType.map(data => ({
          fee: [
            {
              fixedFee: Number(data?.fixedFee) || 0,
              variableFee: Number(data?.variableFee) || 0,
            },
          ],
          feeType: data?.feeType,
          operationType: 'DEPOSIT',
        })),
        ...withdrawalFeesForFeeType.map(data => ({
          fee: [
            {
              fixedFee: Number(data?.fixedFee) || 0,
              variableFee: Number(data?.variableFee) || 0,
            },
          ],
          feeType: data?.feeType,
          operationType: 'WITHDRAWAL',
        })),
      ];

      if (combinedFees.length === 0) return null;

      const feeByOperationType = combinedFees.reduce((acc, fee) => {
        const {operationType, fee: feeDetails} = fee;
        if (!acc[operationType]) acc[operationType] = [];
        acc[operationType].push(...feeDetails);
        return acc;
      }, {});

      return Object.entries(feeByOperationType).map(
        ([operationType, fees]) => ({
          fee: fees, // List of fees for the operation type
          feeType,
          operationType,
        }),
      );
    })
    .filter(Boolean) // Remove nulls
    .flat(); // Flatten array
};

export const generateDailyLimitValidator =
  (weeklyLimit, monthlyLimit) => (_, value, callback) => {
    if (value >= weeklyLimit || value >= monthlyLimit) {
      callback('Limit cannot be greater than weekly or monthly limit');
    } else {
      callback(); // Validation passed
    }
  };

export const generateWeeklyLimitValidator =
  (weeklyLimit, monthlyLimit) => (_, value, callback) => {
    if (value <= weeklyLimit || value >= monthlyLimit) {
      callback(
        'Weekly Limit Cannot be Greater than Monthly Limit or Less than Daily Limit',
      );
    } else {
      callback(); // Validation passed
    }
  };

export const generateMonthlyLimitValidator =
  (weeklyLimit, monthlyLimit) => (_, value, callback) => {
    if (value <= weeklyLimit || value <= monthlyLimit) {
      callback('Monthly Limit Cannot be less than Weekly & Daily Limit');
    } else {
      callback(); // Validation passed
    }
  };
