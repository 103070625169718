import {useNavigate, useParams} from 'react-router-dom';
import styles from './mobileMoney.module.scss';
import backIcon from 'images/back.png';
import {
  Collapse,
  Form,
  Input,
  InputNumber,
  Select,
  Spin,
  Switch,
  Upload,
} from 'antd';
import {useEffect, useState} from 'react';
import imgUpload from 'images/upload_image.png';
import plusIcon from 'images/plus-circle.png';
import deleteIcon from 'images/delete.png';
import {
  mobileMoneySupportedCountries,
  walletCurrencies,
  mobileMoneyOperators,
} from 'utils/mock';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useMutation} from '@apollo/client';
import {UPDATE_MOBILE_MONEY_SETTINGS} from 'graphql/mutations/wallet';
import {toast} from 'react-toastify';
import {Button} from '@dabafinance/react-components';
import {
  UploadDocumentToBucket,
  resizeImageFile,
} from 'pages/campaigns/new/screens/legalDocument/uploadDocument';
import UploadImage from 'components/Startup/profile/companyImage';
import DBModal from 'components/modal/modal';
import MobileMoneyGuide from './guideModal';
import FadeEffect from 'components/animation/fadeEffect';
import trash from 'images/trash-can-outline.svg';
import {fieldRules} from 'utils/helper';

const MobileMoneySettings = ({updateData}) => {
  const [loadingImageUpload, setLoadingImageUpload] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [inputFields, setInputFields] = useState([]);
  const [logoUrl, setLogoUrl] = useState('');
  const [progress, setProgress] = useState(0);
  const [previewLogoUrl, setPreviewLogoUrl] = useState('');
  const [guideModal, setGuideModal] = useState({isOpen: false, id: ''});
  const [updatePaymentProviderSettings, {loading: loadingUpdate}] = useMutation(
    UPDATE_MOBILE_MONEY_SETTINGS,
    {errorPolicy: 'all', onError: error => toast.error(error.message)},
  );
  const {code} = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (updateData) {
      form.setFieldsValue({
        selectedCountry: updateData?.countryName,
        currencyCode: updateData?.currencyCode,
        description: updateData?.subText,
      });
      setLogoUrl(updateData?.logo);
      setPreviewLogoUrl(updateData?.logo);

      setInputFields(
        updateData?.supportedProviders?.map((data, key) => {
          form.setFieldValue(
            `depositFee_${key}`,
            data?.feeSchedule?.deposit?.map(fee => ({
              feeType: fee?.fixedFee ? 'FIXED' : 'VARIABLE',
              fixed: fee?.fixedFee,
              variable: fee?.variableFee,
            })),
          );
          form.setFieldValue(
            `withdrawalFee_${key}`,
            data?.feeSchedule?.withdrawal?.map(fee => ({
              feeType: fee?.fixedFee ? 'FIXED' : 'VARIABLE',
              fixed: fee?.fixedFee,
              variable: fee?.variableFee,
            })),
          );
          form.setFieldValue(
            `withdrawalDiscount_${key}`,
            data?.discount?.withdrawal?.map(fee => ({
              feeType: fee?.fixedFee ? 'FIXED' : 'VARIABLE',
              fixed: fee?.fixedFee,
              variable: fee?.variableFee,
            })),
          );
          form.setFieldValue(
            `depositDiscount_${key}`,
            data?.discount?.deposit?.map(fee => ({
              feeType: fee?.fixedFee ? 'FIXED' : 'VARIABLE',
              fixed: fee?.fixedFee,
              variable: fee?.variableFee,
            })),
          );
          return {
            countryCode: data?.countryCode,
            providerName: data?.providerName,
            providerCode: data?.providerCode,
            logo: data?.providerLogo,
            depositCode: data?.mobileMoneyDepositUSSDCode,
            gateway: data?.gateway,
            isActive: data?.isActive,
            directions: data?.direction,
            minDepositAmount: data?.minDepositAmount,
            maxDepositAmount: data?.maxDepositAmount,
            minWithdrawalAmount: data?.minWithdrawalAmount,
            maxWithdrawalAmount: data?.maxWithdrawalAmount,

            depositDescription: data?.mobileMoneyDepositUSSDCodeDescription,
            withdrawalCode: data?.mobileMoneyWithdrawalUSSDCode,
            withdrawalDescription:
              data?.mobileMoneyWithdrawalUSSDCodeDescription,
            previewImage: data?.providerLogo,
            howToGuide: {
              description: data?.howToGuide?.description,
              imgURL: data?.howToGuide?.imgURL,
              link: data?.howToGuide?.link,
              videoURL: data?.howToGuide?.videoURL,
            },
          };
        }),
      );
    }
  }, [code]);

  const handleChangeFieldValues = (event, index, name) => {
    const newFormValues = [...inputFields];
    newFormValues[index][name] = event;
    setInputFields(newFormValues);
  };

  const handleAddField = () => {
    setInputFields([
      ...inputFields,
      {
        providerCode: '',
        providerName: '',
        previewImage: '',
        depositCode: '',
        gateway: '',
        directions: [],
        withdrawalCode: '',
        countryCode: '',
        fixedDeposit: 0,
        variableDeposit: 0,
        fixedWithdrawal: 0,
        variableWithdrawal: 0,
        minDepositAmount: 0,
        maxDepositAmount: 0,
        minWithdrawalAmount: 0,
        maxWithdrawalAmount: 0,
        howToGuide: {
          description: '',
          imgURL: '',
          link: '',
          videoURL: '',
        },
        depositDescription:
          'Veuillez composer le {CODE} pour valider votre depot via {PROVIDER_NAME}',
        withdrawalDescription:
          'Veuillez composer le {CODE} pour valider votre depot via {PROVIDER_NAME}',
        logo: '',
      },
    ]);
  };

  const handleDeleteField = id => {
    const values = inputFields.filter((_, index) => index !== id);
    setInputFields(values);
  };

  function getCountryCode(key) {
    const country = mobileMoneySupportedCountries.find(
      country => country.key === key,
    );
    return country ? country.countryCode : null;
  }

  const handleUpdate = values => {
    const emptyFieldCheck = inputFields.some(
      data => !data.providerCode || !data.gateway || !data.providerName,
    );
    if (emptyFieldCheck)
      return toast.error(
        'Some Fields are required (Provider Name, Gateway, Provider Code)',
      );

    updatePaymentProviderSettings({
      variables: {
        input: {
          provider: 'FLUTTERWAVE',
          mobileMoney: [
            {
              countryName: values?.selectedCountry,
              currencyCode: values?.currencyCode,
              logo: logoUrl,
              subText: values.description,
              supportedProviders: inputFields?.map((data, key) => ({
                countryCode: getCountryCode(values?.selectedCountry),
                providerName: data?.providerName,
                providerCode: data?.providerCode,
                direction: data?.directions,
                gateway: data?.gateway,
                minDepositAmount: data?.minDepositAmount,
                maxDepositAmount: data?.maxDepositAmount,
                minWithdrawalAmount: data?.minWithdrawalAmount,
                isActive: data?.isActive,
                howToGuide: {
                  ...(data?.howToGuide?.description && {
                    description: data?.howToGuide?.description,
                  }),
                  ...(data?.howToGuide?.imgURL && {
                    imgURL: data?.howToGuide?.imgURL,
                  }),
                  ...(data?.howToGuide?.link && {link: data?.howToGuide?.link}),
                  ...(data?.howToGuide?.videoURL && {
                    videoURL: data?.howToGuide?.videoURL,
                  }),
                },
                maxWithdrawalAmount: data?.maxWithdrawalAmount,
                ...(data?.directions?.length && {
                  feeSchedule: {
                    deposit:
                      values?.[`depositFee_${key}`]?.map(data => ({
                        ...(data?.feeType === 'FIXED'
                          ? {fixedFee: data?.fixed, variableFee: 0}
                          : {
                              fixedFee: 0,
                              variableFee: data?.variable ?? 0,
                            }),
                      })) ?? null,
                    withdrawal:
                      values?.[`withdrawalFee_${key}`]?.map(data => ({
                        ...(data?.feeType === 'FIXED'
                          ? {fixedFee: data?.fixed, variableFee: 0}
                          : {
                              fixedFee: 0,
                              variableFee: data?.variable ?? 0,
                            }),
                      })) ?? null,
                  },
                  // discount: {
                  //   deposit:
                  //     values?.[`depositDiscount_${key}`]?.map(data => ({
                  //       ...(data?.feeType === 'FIXED'
                  //         ? {fixedFee: data?.fixed, variableFee: 0}
                  //         : {
                  //             fixedFee: 0,
                  //             variableFee: data?.variable ?? 0,
                  //           }),
                  //     })) ?? null,
                  //   withdrawal:
                  //     values?.[`withdrawalDiscount_${key}`]?.map(data => ({
                  //       ...(data?.feeType === 'FIXED'
                  //         ? {fixedFee: data?.fixed, variableFee: 0}
                  //         : {
                  //             fixedFee: 0,
                  //             variableFee: data?.variable ?? 0,
                  //           }),
                  //     })) ?? null,
                  // },
                }),
                providerLogo: data.logo,
                mobileMoneyDepositUSSDCode: data?.depositCode,
                mobileMoneyDepositUSSDCodeDescription:
                  data?.depositDescription &&
                  data?.depositCode &&
                  data?.depositDescription?.includes('{CODE}')
                    ? `Veuillez composer le ${data.depositCode} pour valider votre depot via ${data?.providerName}`
                    : data?.depositCode
                    ? data?.depositDescription
                    : null,
                mobileMoneyWithdrawalUSSDCode: data?.withdrawalCode,
                mobileMoneyWithdrawalUSSDCodeDescription:
                  data.withdrawalDescription &&
                  data?.withdrawalCode &&
                  data?.withdrawalDescription?.includes('{CODE}')
                    ? `Veuillez composer le ${data.withdrawalCode} pour valider votre depot via ${data?.providerName}`
                    : data?.withdrawalCode
                    ? data?.withdrawalDescription
                    : null,
              })),
            },
          ],
        },
      },
    })
      .then(({data: {updatePaymentProviderSettings}}) => {
        if (updatePaymentProviderSettings.__typename === 'Error') {
          toast.error(updatePaymentProviderSettings.message);
        } else {
          toast.success(`Updated Successfully.`);
          navigate(-1);
        }
      })
      .catch(error => {
        toast.error(error);
      });
  };

  const handleDeleteImage = i => {
    const newFormValues = [...inputFields];
    newFormValues[i].previewImage = '';
    newFormValues[i].logo = '';
    setInputFields(newFormValues);
  };

  const handleChangeImage = async (i, file) => {
    const temp = file.originFileObj;
    const newFormValues = [...inputFields];
    const resizedFile = await resizeImageFile(temp);
    setLoadingImageUpload(true);
    const res = await UploadDocumentToBucket(
      resizedFile,
      setProgress,
      'momo-operators-logo',
    );
    setLoadingImageUpload(false);
    newFormValues[i].previewImage = res;
    newFormValues[i].logo = res;
    setInputFields(newFormValues);
    setProgress(0);
  };

  return (
    <div className={styles.root}>
      <div className={styles.nav}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>Go to Wallet Overview</h1>
      </div>
      <div className={styles.content}>
        <h1>Mobile Money Settings</h1>
        <UploadImage
          bucket={'momo-operators-logo'}
          previewUrl={previewLogoUrl}
          setImgUrl={setLogoUrl}
          setPreviewUrl={setPreviewLogoUrl}
          label="Upload Country Flag"
        />
        <Form form={form} onFinish={handleUpdate} layout="vertical">
          <Form.Item
            rules={[{required: true, message: 'This Field is Required'}]}
            label="Select Country"
            className={styles.field}
            name="selectedCountry">
            <Select
              value={selectedCountry}
              onChange={setSelectedCountry}
              name="selectedCountry">
              {mobileMoneySupportedCountries.map(data => (
                <Select.Option key={data.key}>{data.value}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            rules={[{required: true, message: 'This Field is Required'}]}
            label="Currency Code"
            className={styles.field}
            name="currencyCode">
            <Select name="currencyCode">
              {walletCurrencies.map(data => (
                <Select.Option key={data.key}>{data.key}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input.TextArea className={styles.field} rows={4} />
          </Form.Item>
          <h2>Supported Mobile Money Operators</h2>
          {inputFields?.map((data, key) => (
            <div key={key} className={styles.fees}>
              <div className={styles['image-section']}>
                <div>
                  {data?.previewImage ? (
                    <div className={styles['c-image-uploaded']}>
                      <img src={data.previewImage} alt="" />
                      <Button
                        className={styles['delete-button']}
                        label="Change Image"
                        type="secondary"
                        onClick={() => handleDeleteImage(key)}
                      />
                    </div>
                  ) : (
                    <Upload
                      accept=".png, .jpeg, .jpg"
                      name="file"
                      onChange={e => handleChangeImage(key, e.file)}>
                      <div className={styles['c-company-upload']}>
                        {loadingImageUpload ? (
                          <div className={styles['loading-spinner']}>
                            <h2>{progress}%</h2>
                            <Spin size="large" />
                            <h2>Loading...</h2>
                          </div>
                        ) : (
                          <>
                            <img
                              className={styles['company-image']}
                              src={imgUpload}
                              alt="upload"
                            />
                            <div style={{marginTop: 6}}>Upload Logo</div>
                          </>
                        )}
                      </div>
                    </Upload>
                  )}
                  <div className={styles.infoModal}>
                    <Button
                      onClick={() =>
                        setGuideModal({
                          isOpen: true,
                          id: key,
                          content: data?.howToGuide,
                        })
                      }
                      label="Open Guide Modal"
                      type="secondary"
                    />
                  </div>
                </div>

                <div>
                  <Form.Item
                    rules={[
                      {required: true, message: 'This Field is Required'},
                    ]}
                    className={styles.field}
                    dependencies={['selectedCountry']}
                    label="Gateway">
                    {() => {
                      return (
                        <Select
                          value={data?.gateway}
                          onChange={e =>
                            handleChangeFieldValues(e, key, 'gateway')
                          }>
                          <Select.Option key="FLUTTERWAVE">
                            Flutterwave
                          </Select.Option>
                          <Select.Option key="HUB2">Hub2</Select.Option>
                        </Select>
                      );
                    }}
                  </Form.Item>
                  <Form.Item
                    label="Provider Code"
                    rules={[
                      {required: true, message: 'This Field is Required'},
                    ]}
                    className={styles.field}>
                    <Select
                      value={data?.providerCode}
                      onChange={e =>
                        handleChangeFieldValues(e, key, 'providerCode')
                      }>
                      {mobileMoneyOperators.map(data => (
                        <Select.Option key={data.key}>
                          {data.value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    initialValue={data.providerName}
                    label="Provider Name">
                    <Input
                      value={data.providerName}
                      defaultValue={data.providerName}
                      name="providerName"
                      className={styles.field}
                      onChange={e =>
                        handleChangeFieldValues(
                          e.target.value,
                          key,
                          'providerName',
                        )
                      }
                    />
                  </Form.Item>
                  <Form.Item
                    initialValue={data.countryCode}
                    dependencies={['selectedCountry']}
                    rules={[
                      {required: true, message: 'This Field is Required'},
                    ]}
                    shouldUpdate={(prevValues, currentValues) =>
                      prevValues.selectedCountry !==
                      currentValues.selectedCountry
                    }
                    label="Country Code">
                    {({getFieldValue}) => {
                      const selectedCountry = getFieldValue('selectedCountry');
                      const defaultCountryCode =
                        getCountryCode(selectedCountry);
                      return (
                        <Select
                          value={
                            data?.countryCode !== ''
                              ? data?.countryCode
                              : defaultCountryCode
                          }
                          onChange={e => {
                            handleChangeFieldValues(e, key, 'countryCode');
                          }}>
                          {mobileMoneySupportedCountries.map(data => (
                            <Select.Option key={data.countryCode}>
                              {data.countryCode}
                            </Select.Option>
                          ))}
                        </Select>
                      );
                    }}
                  </Form.Item>
                  <Form.Item label="Active" name="isActive">
                    <Switch
                      checked={data?.isActive}
                      onChange={e =>
                        handleChangeFieldValues(e, key, 'isActive')
                      }
                    />
                  </Form.Item>
                </div>
                <div className={styles['delete-icon']}>
                  <img
                    onClick={() => handleDeleteField?.(key)}
                    src={deleteIcon}
                    alt=""
                  />
                </div>
              </div>
              <div className={styles.form}>
                <Form.Item
                  className={styles.direction}
                  label="Deposit USSD Message">
                  <Input
                    value={data?.depositDescription}
                    onChange={e =>
                      handleChangeFieldValues(
                        e.target.value,
                        key,
                        'depositDescription',
                      )
                    }
                  />
                </Form.Item>

                <Form.Item
                  className={styles.direction}
                  label="Withdrawal USSD Message">
                  <Input
                    value={data?.withdrawalDescription}
                    onChange={e =>
                      handleChangeFieldValues(
                        e.target.value,
                        key,
                        'withdrawalDescription',
                      )
                    }
                  />
                </Form.Item>
                <Form.Item
                  rules={[{required: true, message: 'This Field is Required'}]}
                  label="Deposit USSD Code">
                  <Input
                    value={data?.depositCode}
                    onChange={e =>
                      handleChangeFieldValues(
                        e.target.value,
                        key,
                        'depositCode',
                      )
                    }
                    placeholder="*1234#"
                  />
                </Form.Item>
                <Form.Item
                  className={styles.codeField}
                  label="Withdrawal USSD Code">
                  <Input
                    value={data?.withdrawalCode}
                    onChange={e =>
                      handleChangeFieldValues(
                        e.target.value,
                        key,
                        'withdrawalCode',
                      )
                    }
                    placeholder="*1234#"
                  />
                </Form.Item>
                <Form.Item className={styles.direction} label="Direction(s)">
                  <Select
                    value={data?.directions}
                    onChange={e =>
                      handleChangeFieldValues(e, key, 'directions')
                    }
                    mode="tags">
                    <Select.Option key="DEPOSIT">Deposit</Select.Option>
                    <Select.Option key="WITHDRAWAL">Withdrawal</Select.Option>
                  </Select>
                </Form.Item>

                <div className={styles.feesDropdown}>
                  {data?.directions?.includes('DEPOSIT') && (
                    <Collapse className={styles.collapse}>
                      <Collapse.Panel
                        key={`deposit_${key}`}
                        header={
                          <h2 className={styles.collapseHeader}>Deposit</h2>
                        }>
                        <div className={styles.scheduleFees}>
                          <div>
                            <Form.Item label="Min. Deposit Amount">
                              <InputNumber
                                value={data?.minDepositAmount}
                                className={styles.minNumber}
                                formatter={value =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ',',
                                  )
                                }
                                parser={value =>
                                  value.replace(/\$\s?|(,*)/g, '')
                                }
                                onChange={e =>
                                  handleChangeFieldValues(
                                    e,
                                    key,
                                    'minDepositAmount',
                                  )
                                }
                              />
                            </Form.Item>
                            <Form.Item label="Max. Deposit Amount">
                              <InputNumber
                                value={data?.maxDepositAmount}
                                className={styles.minNumber}
                                formatter={value =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ',',
                                  )
                                }
                                parser={value =>
                                  value.replace(/\$\s?|(,*)/g, '')
                                }
                                onChange={e =>
                                  handleChangeFieldValues(
                                    e,
                                    key,
                                    'maxDepositAmount',
                                  )
                                }
                              />
                            </Form.Item>
                          </div>

                          <div className={styles.multipleFees}>
                            <div className={styles.mFee}>
                              <h3>Fee Schedule</h3>
                              <FeeScheduleInput
                                form={form}
                                formName={`depositFee_${key}`}
                                btnText="Add Deposit Fee"
                              />
                            </div>
                            {/* <div className={styles.mFee}>
                              <h3>Discount Schedule</h3>
                              <FeeScheduleInput
                                form={form}
                                formName={`depositDiscount_${key}`}
                                btnText="Add Deposit Discount"
                              />
                            </div> */}
                          </div>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  )}
                  {data?.directions?.includes('WITHDRAWAL') && (
                    <Collapse>
                      <Collapse.Panel
                        key={`withdrawal_${key}`}
                        header={
                          <h2 className={styles.collapseHeader}>Withdrawal</h2>
                        }>
                        <div className={styles.scheduleFees}>
                          <div>
                            <Form.Item label="Min. Withdrawal Amount">
                              <InputNumber
                                value={data?.minWithdrawalAmount}
                                className={styles.minNumber}
                                formatter={value =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ',',
                                  )
                                }
                                parser={value =>
                                  value.replace(/\$\s?|(,*)/g, '')
                                }
                                onChange={e =>
                                  handleChangeFieldValues(
                                    e,
                                    key,
                                    'minWithdrawalAmount',
                                  )
                                }
                              />
                            </Form.Item>
                            <Form.Item label="Max. Withdrawal Amount">
                              <InputNumber
                                className={styles.minNumber}
                                formatter={value =>
                                  `${value}`.replace(
                                    /\B(?=(\d{3})+(?!\d))/g,
                                    ',',
                                  )
                                }
                                parser={value =>
                                  value.replace(/\$\s?|(,*)/g, '')
                                }
                                value={data?.maxWithdrawalAmount}
                                onChange={e =>
                                  handleChangeFieldValues(
                                    e,
                                    key,
                                    'maxWithdrawalAmount',
                                  )
                                }
                              />
                            </Form.Item>
                          </div>
                          <div className={styles.multipleFees}>
                            <div className={styles.mFee}>
                              <h3>Fee Schedule</h3>
                              <FeeScheduleInput
                                form={form}
                                formName={`withdrawalFee_${key}`}
                                btnText="Add Withdrawal Fee"
                              />
                            </div>
                            {/* <div className={styles.mFee}>
                              <h3>Discount Schedule</h3>
                              <FeeScheduleInput
                                form={form}
                                formName={`withdrawalDiscount_${key}`}
                                btnText="Add Withdrawal Discount"
                              />
                            </div> */}
                          </div>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  )}
                </div>
              </div>
            </div>
          ))}
          <div onClick={handleAddField} className={styles['add-button']}>
            <img src={plusIcon} alt="" />
            <h2>Add Field</h2>
          </div>
          <SubmitButton
            disabled={loadingUpdate}
            label={code ? 'Update' : 'Create'}
          />
        </Form>
      </div>
      <DBModal
        isOpen={guideModal.isOpen}
        handleClose={() => setGuideModal({isOpen: false})}
        width={800}
        content={
          <MobileMoneyGuide
            index={guideModal.id}
            content={guideModal.content}
            onClose={() => setGuideModal({isOpen: false})}
            existingData={inputFields}
            handleExistingData={setInputFields}
          />
        }
      />
    </div>
  );
};

const FeeScheduleInput = ({formName, btnText, form}) => {
  const handleChangeFeeSchedule = (name, key, value) => {
    form.setFieldsValue({
      [name]: form.getFieldValue(name).map((item, index) =>
        index === name
          ? {
              ...item,
              [key]: value,
            }
          : item,
      ),
    });
  };

  return (
    <Form.List name={formName}>
      {(fields, {add, remove}) => (
        <div className={styles['fee-list']}>
          {fields.map(({name}) => (
            <div key={name}>
              <FadeEffect>
                <div className={styles['fee-item']}>
                  <Form.Item
                    className={styles.feeType}
                    name={[name, 'feeType']}
                    label="Fee Type">
                    <Select
                      placeholder="FIXED"
                      allowClear
                      onChange={value =>
                        handleChangeFeeSchedule(formName, 'feeType', value)
                      }>
                      <Select.Option key="FIXED">Fixed</Select.Option>
                      <Select.Option key="VARIABLE">Variable</Select.Option>
                    </Select>
                  </Form.Item>
                  {form.getFieldValue(formName)?.[name]?.feeType ===
                  'VARIABLE' ? (
                    <Form.Item label={'Variable(%)'} name={[name, 'variable']}>
                      <InputNumber
                        className={styles.feeNumber}
                        min={0}
                        max={100}
                        placeholder="10%"
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item
                      label={'Fixed'}
                      name={[name, 'fixed']}
                      rules={[fieldRules('This')]}>
                      <InputNumber
                        className={styles.feeNumber}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        placeholder="10"
                      />
                    </Form.Item>
                  )}
                  <img src={trash} onClick={() => remove(name)} alt="" />
                </div>
              </FadeEffect>
            </div>
          ))}
          <div onClick={add} className={styles['add']}>
            <span>{btnText}</span>
          </div>
        </div>
      )}
    </Form.List>
  );
};

export default MobileMoneySettings;
