import {Form, Input, InputNumber, Select, Spin, Switch} from 'antd';
import styles from './createFxExchangeRate.module.scss';
import {walletCurrencies} from 'utils/mock';
import {useNavigate, useParams} from 'react-router-dom';
import backIcon from 'images/back.png';
import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  CREATE_FX_EXCHANGE_RATE,
  UPDATE_FX_EXCHANGE_RATE,
} from 'graphql/mutations/wallet';
import {toast} from 'react-toastify';
import SubmitButton from 'components/Startup/components/SubmitButton';
import {useEffect, useState} from 'react';
import {
  GET_ALL_FX_EXCHANGE_RATES,
  GET_FX_EXCHANGE_RATE_BY_ID,
  GET_PUBLIC_EXCHANGE_RATES,
} from 'graphql/queries/wallet';
import moment from 'moment';
import DBModal from 'components/modal/modal';
import {Button} from '@dabafinance/react-components';
import {fieldRules} from 'utils/helper';

const CreateFXExchangeRate = () => {
  const [sourceCurrency, setSourceCurrency] = useState('');
  const [targetCurrency, setTargetCurrency] = useState('');
  const [currentRate, setCurrentRate] = useState({});
  const [finishModal, setFinishModal] = useState(false);
  const [fieldValues, setFieldValues] = useState({});
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const {id} = useParams();
  const feeType = Form.useWatch('feeType', form);
  // const discountFeeType = Form.useWatch('discountFeeType', form);

  const {data, loading} = useQuery(GET_FX_EXCHANGE_RATE_BY_ID, {
    variables: {
      getFxExchangeRateByIdId: id,
    },
    fetchPolicy: 'no-cache',
  });

  const [createFxExchangeRate, {loading: loadingCreate}] = useMutation(
    CREATE_FX_EXCHANGE_RATE,
  );

  const [updateFxExchangeRate, {loading: loadingUpdate}] = useMutation(
    UPDATE_FX_EXCHANGE_RATE,
  );

  const [getPublicFxExchangeRates, {loading: loadingRates}] = useLazyQuery(
    GET_PUBLIC_EXCHANGE_RATES,
  );

  useEffect(() => {
    if (id && data) {
      const dbData = data?.getFxExchangeRateById;
      setSourceCurrency(dbData?.sourceCurrency);
      setTargetCurrency(dbData?.targetCurrency);
      form.setFieldsValue({
        sourceCurrency: dbData?.sourceCurrency,
        targetCurrency: dbData?.targetCurrency,
        rate: dbData?.rate,
        minAmount: dbData?.minSwapAmountInSourceCurrency,
        maxAmount: dbData?.maxSwapAmountInSourceCurrency,
        // discountFeeType: dbData?.discount?.mode,
        // discountFixedFee: dbData?.discount?.fixedFee,
        // discountVariableFee: dbData?.discount?.variableFee,
        feeType: dbData?.feeSchedule?.mode,
        variableFee: dbData?.feeSchedule?.variableFee,
        fixedFee: dbData?.feeSchedule?.fixedFee,
        shouldCheckMarketRate: dbData?.shouldCheckMarketRate,
      });
    }
  }, [data]);

  useEffect(() => {
    if (sourceCurrency && targetCurrency) {
      getPublicFxExchangeRates({
        variables: {
          from: sourceCurrency,
          to: [targetCurrency],
        },
        onCompleted: data => {
          setCurrentRate(data?.getPublicFxExchangeRates);
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [sourceCurrency, targetCurrency]);

  const handleSubmit = () => {
    const values = fieldValues;
    const optionalFields = {
      input: {
        sourceCurrency: values.sourceCurrency,
        targetCurrency: values.targetCurrency,
        rate: Number(values.rate),
        shouldCheckMarketRate: values?.shouldCheckMarketRate,
        ...(values.maxAmount && {
          maxSwapAmountInSourceCurrency: Number(values?.maxAmount),
        }),
        ...(values.minAmount && {
          minSwapAmountInSourceCurrency: Number(values?.minAmount),
        }),
        ...(values?.discountFeeType && {
          discount: {
            mode: values?.discountFeeType,
            ...(values?.discountFeeType === 'FIXED'
              ? {fixedFee: Number(values?.discountFixedFee)}
              : {variableFee: Number(values?.discountVariableFee)}),
          },
        }),
        feeSchedule: {
          mode: values.feeType,
          fixedFee: Number(values.fixedFee),
          variableFee: Number(values.variableFee),
        },
      },
    };
    if (id) {
      updateFxExchangeRate({
        variables: {
          ...optionalFields,
        },
        refetchQueries: [{query: GET_ALL_FX_EXCHANGE_RATES}],
      })
        .then(({data: {updateFxExchangeRate}}) => {
          if (updateFxExchangeRate.__typename === 'Error') {
            toast.error(updateFxExchangeRate.message);
          } else {
            toast.success('Swap Pair Update Successfully');
            navigate(-1);
          }
        })
        .catch(error => {
          toast.error(error);
        });
    } else
      createFxExchangeRate({
        variables: {
          ...optionalFields,
        },
        refetchQueries: [{query: GET_ALL_FX_EXCHANGE_RATES}],
      })
        .then(({data: {createFxExchangeRate}}) => {
          if (createFxExchangeRate.__typename === 'Error') {
            toast.error(createFxExchangeRate.message);
          } else {
            toast.success('Swap Pair Created Successfully');
            navigate(-1);
          }
        })
        .catch(error => {
          toast.error(error);
        });
  };

  if (id && loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} alt="" />
        <h1>{id ? 'Update' : 'Create'} Swap Currency Pair</h1>
      </div>
      <Form
        form={form}
        onFinish={e => {
          setFieldValues(e);
          setFinishModal(true);
        }}
        layout="vertical">
        <Form.Item
          rules={[{required: true, message: 'This Field is required'}]}
          name="sourceCurrency"
          className={styles.bigWidth}
          label="Source Currency">
          <Select value={sourceCurrency} onChange={setSourceCurrency}>
            {walletCurrencies.map(data => (
              <Select.Option key={data.key}>{data.key}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          className={styles.bigWidth}
          rules={[{required: true, message: 'This Field is required'}]}
          name="targetCurrency"
          label="Target Currency">
          <Select value={targetCurrency} onChange={setTargetCurrency}>
            {walletCurrencies.map(data => (
              <Select.Option key={data.key}>{data.key}</Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="minAmount"
          label="Minimum Swap Amount For Source Currency">
          <InputNumber
            className={styles.bigWidth}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <Form.Item
          name="maxAmount"
          label="Maximum Swap Amount For Source Currency">
          <InputNumber
            className={styles.bigWidth}
            formatter={value =>
              `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
          />
        </Form.Item>
        <div className={styles.rate}>
          <Form.Item
            rules={[{required: true, message: 'This Field is required'}]}
            name="rate"
            className={styles.bigWidth}
            label="Rate">
            <Input type="number" />
          </Form.Item>
          <div className={styles.values}>
            {loadingRates ? (
              <Spin />
            ) : Object.values(currentRate).length ? (
              <p>
                1 {sourceCurrency} = {currentRate?.to[0]?.rate}
                {targetCurrency}{' '}
                <span>
                  (
                  {moment(currentRate?.lastUpdated).format(
                    'DD MMMM YYYY hh:mm A',
                  )}
                  )
                </span>
              </p>
            ) : (
              ''
            )}
          </div>
        </div>
        <Form.Item
          name="shouldCheckMarketRate"
          label="Should Check Market Rate">
          <Switch
            defaultChecked={data?.getFxExchangeRateById?.shouldCheckMarketRate}
          />
        </Form.Item>
        <h2>Rate Schedule (Fees)</h2>
        <div className={styles.fees}>
          <Form.Item
            rules={[{required: true, message: 'This field is required'}]}
            name="feeType"
            className={styles.width}
            label="Rate Type">
            <Select>
              <Select.Option key="FIXED">Fixed</Select.Option>
              <Select.Option key="VARIABLE">Variable</Select.Option>
            </Select>
          </Form.Item>
          {feeType === 'VARIABLE' ? (
            <Form.Item
              rules={[fieldRules('This')]}
              name="variableFee"
              label="Variable Rate (%)">
              <Input type="number" className={styles.width} />
            </Form.Item>
          ) : (
            <Form.Item
              rules={[fieldRules('This')]}
              name="fixedFee"
              label="Fixed Rate">
              <Input type="number" className={styles.width} />
            </Form.Item>
          )}
        </div>
        {/* <h2>Discount Rate Schedule (For Daba PRO Users)</h2>
        <div className={styles.fees}>
          <Form.Item
            name="discountFeeType"
            className={styles.width}
            label="Discount Rate Type">
            <Select allowClear placeholder="Fixed">
              <Select.Option key="FIXED">Fixed</Select.Option>
              <Select.Option key="VARIABLE">Variable</Select.Option>
            </Select>
          </Form.Item>
          {discountFeeType !== 'VARIABLE' ? (
            <Form.Item
              rules={[fieldRules('This', !!discountFeeType)]}
              name="discountFixedFee"
              label="Discount Fixed Rate">
              <Input type="number" className={styles.width} />
            </Form.Item>
          ) : (
            <Form.Item
              rules={[fieldRules('This', !!discountFeeType)]}
              name="discountVariableFee"
              label="Discount Variable Rate (%)">
              <Input type="number" className={styles.width} />
            </Form.Item>
          )}
        </div> */}
        <DBModal
          isOpen={finishModal}
          handleClose={() => setFinishModal(false)}
          content={
            <div className="finishScreen">
              <h1>
                You are about to {id ? 'Update' : 'Create'} A Currency Pair for
                Swap
              </h1>
              <p>Click Yes to Confirm and Proceed</p>
              <div className="finish-buttons">
                <Button
                  className="finish-button"
                  label="NO"
                  type="secondary"
                  onClick={() => setFinishModal(false)}
                />
                <Button
                  className="finish-button"
                  disabled={loadingCreate || loadingUpdate}
                  label="YES"
                  onClick={handleSubmit}
                />
              </div>
            </div>
          }
        />
        <SubmitButton label={id ? 'Update' : 'Create'} />
      </Form>
    </div>
  );
};

export default CreateFXExchangeRate;
