import {Form, Spin} from 'antd';
import styles from './walletDetails.module.scss';
import {useNavigate, useParams} from 'react-router-dom';
import {GET_BOTH_PLAFORM_AND_WALLET_CURRENCY} from 'graphql/queries/wallet';
import {useMutation, useQuery} from '@apollo/client';
import {useEffect} from 'react';
import DepositWalletFields from './WalletFields/DepositWalletField';
import WithdrawalWalletFields from './WalletFields/WithdrawalWalletFields';
import backIcon from 'images/back.png';
import {checkFeeValues} from '../create/CreateProviderSettings';
import {handleUpdatePaymentProviderSetting} from '../actions/MutationRequests';
import {toast} from 'react-toastify';
import {UPDATE_PAYMENT_PROVIDER_SETTINGS} from 'graphql/mutations/wallet';

const CurrencyDetails = () => {
  const {provider, currency} = useParams();
  const [form] = Form.useForm();

  const navigate = useNavigate();

  const [updatePaymentProviderCurrencySettings, {loading: loadingUpdate}] =
    useMutation(UPDATE_PAYMENT_PROVIDER_SETTINGS);

  const handleUpdate = values => {
    if (
      checkFeeValues(
        values?.withdrawalFees ?? [],
        Number(values.minWithdrawalAmount),
      )
    )
      return toast.error(
        'Fee Values must be LESS than Minimum Withdrawal Amount',
      );

    if (
      checkFeeValues(values?.depositFees ?? [], Number(values.minDepositAmount))
    )
      return toast.error('Fee Values must be LESS than Minimum Deposit Amount');

    handleUpdatePaymentProviderSetting(
      updatePaymentProviderCurrencySettings,
      values,
      provider,
      true,
      currency,
      values?.depositFees,
      values?.withdrawalFees,
      values?.depositDiscount,
      values?.withdrawalDiscount,
      navigate,
    );
  };

  const {loading, data} = useQuery(GET_BOTH_PLAFORM_AND_WALLET_CURRENCY, {
    variables: {
      providerName: provider,
      currency,
      currency2: currency,
    },
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  });

  useEffect(() => {
    const currencies = data?.getPaymentProviderByProviderName?.currencies;
    if (!currencies) return;

    const {feeSchedule, discount} = currencies?.[currency];
    if (!feeSchedule?.length) return;
    const fees = feeSchedule.reduce(
      (acc, {operationType, fee, feeType}) => {
        if (!fee?.length) return acc;

        const formattedFees = fee.map(f => ({
          variableFee: f.variableFee,
          fixedFee: f.fixedFee,
          feeType,
        }));

        if (operationType === 'DEPOSIT') acc.depositFees.push(...formattedFees);
        if (operationType === 'WITHDRAWAL')
          acc.withdrawalFees.push(...formattedFees);

        return acc;
      },
      {depositFees: [], withdrawalFees: []},
    );

    // HANDLE DISCOUNT FEES
    if (!discount?.length) return;
    const discounts = discount.reduce(
      (acc, {operationType, fee, feeType}) => {
        if (!fee?.length) return acc;

        const formattedFees = fee.map(f => ({
          variableFee: f.variableFee,
          fixedFee: f.fixedFee,
          feeType,
        }));

        if (operationType === 'DEPOSIT')
          acc.depositDiscount.push(...formattedFees);
        if (operationType === 'WITHDRAWAL')
          acc.withdrawalDiscount.push(...formattedFees);

        return acc;
      },
      {depositDiscount: [], withdrawalDiscount: []},
    );

    form.setFieldsValue({
      ...fees,
      ...discounts,
    });
  }, [data?.getPaymentProviderByProviderName?.currencies, currency]);

  if (loading) {
    return (
      <div className="query-loading">
        <Spin size="large" />
        <h1>Loading...</h1>
      </div>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.header}>
        <img onClick={() => navigate(-1)} src={backIcon} />
        <h1>{currency} WALLET DETAILS</h1>
      </div>
      <Form onFinish={handleUpdate} form={form} layout="vertical">
        {currency && (
          <>
            <DepositWalletFields
              selectedCurrency={currency}
              form={form}
              currencyLimits={
                data?.getSupportedCurrencyByCurrencyName?.deposit?.limit
              }
              currencyDetails={
                data?.getPaymentProviderByProviderName?.currencies
              }
            />
            <WithdrawalWalletFields
              selectedCurrency={currency}
              currencyLimits={
                data?.getSupportedCurrencyByCurrencyName?.withdrawal?.limit
              }
              loading={loadingUpdate}
              form={form}
              currencyDetails={
                data?.getPaymentProviderByProviderName?.currencies
              }
            />
          </>
        )}
      </Form>
    </div>
  );
};

export default CurrencyDetails;
